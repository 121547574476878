<script setup lang="ts">
interface Props {
  name: string;
  animation?: "spin";
}

const props = withDefaults(defineProps<Props>(), {});

const classObject = computed(() => ({
  [`has-animation-${props.animation}`]: props.animation,
}));
</script>

<template>
  <span class="icon material-symbols-rounded" :class="classObject">
    {{ props.name }}
  </span>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.icon {
  &.has-animation-spin {
    animation: spin 1500ms linear infinite;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
